.callout {
  margin: 1em 0;
  padding: 0.5em 2em;
  border-left: 0.3em solid #eee;
  display: inline-block;

  & h6 {
    font-size: 1.2em;
    margin: 0;
  }

  &-bad {
    background-color: #fefbed;
    border-color: #f1e7bc;

    & h6 {
      color: #C09853;
    }
  }

  &-good {
    background-color: #f0f7fd;
    border-color: #d0e3f0;

    & h6 {
      color: #3A87AD;
    }
  }
}