.docs-menu {
  line-height: 1.8em;

  & a {
    font-size: 1.2em;
    color: #444;
  }

  & li.active > a {
    font-weight: bold;
  }
}

.docs-content {
  & h4, h5 {
    margin: 1.5em 0 0.7em 0;
  }

  & ul {
    list-style: none;
    margin-left: 1.5em;

    & li {
      margin-top: 0.5em;
    }
  }

  & code {
    margin: 0.3em 0;
  }

  & .badge {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  & i a {
    color: #444;
  }
}

.docs-anchor {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}