$footer-text-color: #555;

.app {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.app-content {
  flex: 1;
}

.logo-text {
  font-family: ColonnaMT;
  font-size: 5.7em;
  color: #224;
  letter-spacing: 3px;
  z-index: 2;
  margin-top: -5px;
  position: relative;
}

.logo-img {
  width: 7.5em;
  margin-top: 1em;
  z-index: 2;
  position: relative;
}

.row-header {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: #f9f7ff;
    background-image: url("#{$img-path}bg/cream-pixels.png");
  }
}

.row-slogan {
  background: #F3F3FF;
  padding: 0.1em 0 0 0;
  z-index: 2;
  position: relative;
}

.menu {
  background: #F3F3FF;
}

.menu-logo {
  width: 2em;
}

.menu-logo-text {
  color: #224;
  letter-spacing: 1px;
  padding-left: 0.2em;

  & a {
    text-decoration: none;
    color: #000;
  }
}

.logo-code {
  background: #F3F3FF;
}

.home-iframe {
  margin-top: 2.5em;
  border: 1px #F1F1F1 solid;
}

.footer {
  background: #F1F1FF;
  color: $footer-text-color;
}

.footer-logo {
  text-decoration: none !important;
}

.footer-logo-img {
  width: 2em;
  opacity: 0.8;
  vertical-align: middle;
}

.footer-logo-text {
  color: #485482;
  display: inline-block;
  vertical-align: middle;
}

.footer-information {
  font-size: 0.9em;
  line-height: 1.7em;

  & a {
    color: $footer-text-color;
  }
}

.footer-contacts {
  font-size: 1em;
  line-height: 1.7em;

  & a {
    color: $footer-text-color;
  }
}

.footer-donate {
  font-size: 0.75em;
  line-height: 1.7em;

  & a {
    color: $footer-text-color;
  }
}

.jsfiddle {
  position: absolute;
  top: 0.3em;
  right: 1.1em;
}
