$font-path: '../fonts/';
$img-path: '../img/';

@font-face {
  font-family: ColonnaMT;
  src: url("#{$font-path}ColonnaMT.ttf");
}

@font-face {
  font-family: OpenSans;
  src: url("#{$font-path}OpenSans.ttf");
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: OpenSans;
}

.cr-pointer {
  cursor: pointer;
}

button {
  cursor: pointer;
}








